import { all, call, put, takeLatest } from 'redux-saga/effects';
import types from './types';
import { Api } from '../../services';
import { ApiConstant } from '../../constants';
import { buildUrl } from '../state.utils';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { customerOperations } from '.';

function* getCustomerList(action) {
  yield put({ type: types.GET_ALL_CUSTOMER_IN_PROGRESS });
  try {
    const response = yield call(Api.get, ApiConstant.GET_ALL_CUSTOMERS);
    yield put({
      type: types.GET_ALL_CUSTOMER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.GET_ALL_CUSTOMER_FAILURE,
      error: error,
    });
  }
}

function* getCustomerPaginationList(action) {
  yield put({ type: types.GET_ALL_CUSTOMER_IN_PROGRESS });
  try {
    const url = buildUrl(ApiConstant.GET_ALL_CUSTOMERS_PAGINATION, {
      nextToken: action.payload,
    });
    const response = yield call(Api.get, url, {
      nextToken: action.payload,
    });
    yield put({
      type: types.GET_ALL_CUSTOMER_SUCCESS,
      payload: response?.data,
    });
    if (response?.hasMore) {
      yield put({
        type: types.GET_ALL_CUSTOMER_REQUEST,
        payload: response?.nextToken,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_ALL_CUSTOMER_PAGINATION_FAILURE,
      error: error,
    });
  }
}

function* getCurrentBusinessBalance(action) {
  const currentBusinessId = localStorage.getItem('currentBusinessId');
  const url = yield call(buildUrl, ApiConstant.GET_BUSINESS_BALANCE, {
    businessId: currentBusinessId,
  });
  yield put({ type: types.GET_BUSINESS_BALANCE_IN_PROGRESS });
  try {
    const response = yield call(Api.get, url);
    yield put({
      type: types.GET_BUSINESS_BALANCE_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_BUSINESS_BALANCE_FAILURE,
      error: error,
    });
  }
}

function* addNewCustomer(action) {
  yield put({ type: types.CREATE_CUSTOMER_IN_PROGRESS });
  try {
    const response = yield call(Api.post, ApiConstant.ADD_CUSTOMERS, action.payload);
    action.onSuccess && action.onSuccess(response);
    message.success('New customer added');
    yield put({
      type: types.CREATE_CUSTOMER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    action.onError && action.onError(error);
    message.error(error?.title);
    yield put({
      type: types.CREATE_CUSTOMER_FAILURE,
      error: error,
    });
  }
}

function* addBulkCustomer(action) {
  const currentBusinessId = localStorage.getItem('currentBusinessId');
  const url = buildUrl(ApiConstant.ADD_BULK_CUSTOMERS, {
    businessId: currentBusinessId,
  });
  yield put({ type: types.ADD_BULK_CUSTOMER_IN_PROGRESS });
  try {
    const response = yield call(Api.post, url, action.payload);
    action.onSuccess && action.onSuccess(response.data);
    message.success('New customers added');
    yield put({
      type: types.ADD_BULK_CUSTOMER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    action.onError && action.onError(error);
    message.error(error?.message);
    yield put({
      type: types.ADD_BULK_CUSTOMER_FAILURE,
      error: error,
    });
  }
}

function* updateCustomer(action) {
  yield put({ type: types.UPDATE_CUSTOMER_IN_PROGRESS });
  const url = buildUrl(ApiConstant.UPDATE_CUSTOMER, {
    customerId: action.customerId,
  });
  try {
    const response = yield call(Api.put, url, action.payload);
    action.onSuccess && action.onSuccess(response.data);
    message.success('Customer Details Updated');
    yield put({
      type: types.UPDATE_CUSTOMER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    action.onError && action.onError(error);
    message.error(error?.message);
    yield put({
      type: types.UPDATE_CUSTOMER_FAILURE,
      error: error,
    });
  }
}

function multiPostApi(url, payload) {
  return Promise.allSettled([...payload.map((txn) => Api.post(url, txn))]).then((res) => res);
}

function* BulkSettleUp(action) {
  const url = ApiConstant.ADD_CUSTOMER_TXN;

  yield put({ type: types.BULK_SETTLE_TXN_IN_PROGRESS });
  try {
    const response = yield call(multiPostApi, url, action.payload);
    action.onSuccess && action.onSuccess(response);
    yield put({
      type: types.BULK_SETTLE_TXN_SUCCESS,
      payload: response,
    });
  } catch (error) {
    action.onError && action.onError(action.error);
    yield put({
      type: types.BULK_SETTLE_TXN_FAILURE,
      error: error,
    });
  }
}
function* deleteCustomer(action) {
  yield put({ type: types.DELETE_CUSTOMER_IN_PROGRESS });
  const url = buildUrl(ApiConstant.DELETE_CUSTOMER, {
    customerId: action.customerId,
  });
  try {
    const response = yield call(Api.delete, url);
    action.onSuccess && action.onSuccess(action.customerId);
    message.success('Customer deleted');
    yield put({
      type: types.DELETE_CUSTOMER_SUCCESS,
      payload: action.customerId,
    });
  } catch (error) {
    message.error(error);
    action.onError && action.onError(error);
    yield put({
      type: types.DELETE_CUSTOMER_FAILURE,
      error: error,
    });
  }
}

function deleteBulkCustApi(urls) {
  return Promise.allSettled([...urls.map((url) => Api.delete(url))]).then((res) => res);
}

function* deleteBulkCustomers(action) {
  yield put({ type: types.DELETE_BULK_CUSTOMER_IN_PROGRESS });
  const urls = action.customerIds?.map((customerId) =>
    buildUrl(ApiConstant.DELETE_CUSTOMER, {
      customerId: customerId,
    })
  );
  try {
    const response = yield call(deleteBulkCustApi, urls);
    const deleted = response.filter(({ status }) => status === 'fulfilled');
    if (deleted?.length) {
      action.onSuccess && action.onSuccess(action.customerId);
      message.success(`${deleted?.length} Customers deleted`, 3);
      yield put({
        type: types.DELETE_BULK_CUSTOMER_SUCCESS,
        payload: action.customerIds,
      });
    } else {
      const reason = response[0]?.reason;
      message.error(reason, 3);
      action.onError && action.onError(reason);
      yield put({
        type: types.DELETE_BULK_CUSTOMER_FAILURE,
        error: reason,
      });
    }
  } catch (error) {
    message.error(error);
    action.onError && action.onError(error);
    yield put({
      type: types.DELETE_BULK_CUSTOMER_FAILURE,
      error: error,
    });
  }
}

export function* watchCustomer() {
  yield all([
    // takeLatest(types.GET_ALL_CUSTOMER_REQUEST, getCustomerList),
    takeLatest(types.GET_BUSINESS_BALANCE_REQUEST, getCurrentBusinessBalance),
    takeLatest(types.CREATE_CUSTOMER_REQUEST, addNewCustomer),
    takeLatest(types.ADD_BULK_CUSTOMER_REQUEST, addBulkCustomer),
    takeLatest(types.UPDATE_CUSTOMER_REQUEST, updateCustomer),
    takeLatest(types.DELETE_CUSTOMER_REQUEST, deleteCustomer),
    takeLatest(types.BULK_SETTLE_TXN_REQUEST, BulkSettleUp),
    takeLatest(types.DELETE_BULK_CUSTOMER_REQUEST, deleteBulkCustomers),
    takeLatest(types.GET_ALL_CUSTOMER_REQUEST, getCustomerPaginationList),
    takeLatest(types.GET_ALL_CUSTOMER_REQUEST1, getCustomerList),

  
  ]);
}
