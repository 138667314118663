import types from './types';
import { capitalize } from 'lodash';

const INITIAL_VALUE = {
  selected: null,
  customerFilters: { id: 'all' },
  error: null,
  list: [],
  listLoading: true,
  listError: false,
  addUpdateCustomerLoading: false,
  addUpdateCustomerError: false,
  mainBalance: {
    dues: 0,
    advances: 0,
  },
  balanceLoading: true,
  balanceError: false,
  bulkSettleLoading: false,
  bulkSettleError: null,
  bulkDeleteLoading: false,
  bulkDeleteError: null,
};

const reducer = (state = INITIAL_VALUE, action) => {
  switch (action.type) {
    /**
     * GET All business list
     */
    case types.EMPTY_CUSTOMER_LIST:
      return { ...state, list: [] };
    case types.GET_ALL_CUSTOMER_IN_PROGRESS:
      return { ...state, listLoading: true, listError: null };
    case types.GET_ALL_CUSTOMER_SUCCESS:
      const _customers = [...action.payload];
      const customers = _customers.map((c) => ({
        ...c,
        name: capitalize(c.name),
      }));
      let copycustomers = [];
      if (_customers.length) {
        copycustomers = [...state.list, ..._customers];
      }
      return {
        ...state,
        listLoading: false,
        list: copycustomers,
        listError: null,
      };
    case types.GET_ALL_CUSTOMER_FAILURE:
      return { ...state, listLoading: false, listError: action.error };
    /**
     * GET business Balance
     */
    case types.GET_BUSINESS_BALANCE_IN_PROGRESS:
      return { ...state, balanceLoading: true, balanceError: null };
    case types.GET_BUSINESS_BALANCE_SUCCESS:
      return {
        ...state,
        balanceLoading: false,
        mainBalance: action.payload,
        balanceError: null,
      };
    case types.GET_BUSINESS_BALANCE_FAILURE:
      return {
        ...state,
        balanceLoading: false,
        balanceError: action.error,
      };
    /**
     * Add Customer
     */
    case types.CREATE_CUSTOMER_IN_PROGRESS:
      return {
        ...state,
        addUpdateCustomerLoading: true,
        addUpdateCustomerError: null,
      };
    case types.CREATE_CUSTOMER_SUCCESS:
      const newList = [...state.list];
      newList.unshift(action.payload);
      return {
        ...state,
        list: newList,
        addUpdateCustomerLoading: false,
        addUpdateCustomerError: null,
      };
    case types.CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        addUpdateCustomerLoading: false,
        addUpdateCustomerError: action.error,
      };
    /**
     * BULK_CUSTOMER ADD
     */
    case types.ADD_BULK_CUSTOMER_IN_PROGRESS:
      return { ...state, isLoading: true, error: false };
    case types.ADD_BULK_CUSTOMER_SUCCESS:
      const newList2 = [...action.payload, ...state.list];
      return { ...state, isLoading: false, list: newList2, error: false };
    case types.ADD_BULK_CUSTOMER_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    /**
     * Update Customer
     */
    case types.UPDATE_CUSTOMER_IN_PROGRESS:
      return {
        ...state,
        addUpdateCustomerLoading: true,
        addUpdateCustomerError: null,
      };
    case types.UPDATE_CUSTOMER_SUCCESS:
      const newList1 = [...state.list];
      const newCustomer = action.payload;
      const index = newList1.findIndex((c) => c.id === newCustomer.id);
      newList1[index] = newCustomer;
      return {
        ...state,
        list: newList1,
        addUpdateCustomerLoading: false,
        addUpdateCustomerError: null,
      };
    case types.UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        addUpdateCustomerLoading: false,
        addUpdateCustomerError: action.error,
      };

    /**
     * Delete customer
     */
    case types.DELETE_CUSTOMER_SUCCESS:
      let list1 = [...state.list];
      const deletedCustId = action.payload;
      list1 = list1.filter((c) => c.id != deletedCustId);
      return {
        ...state,
        list: list1,
        addUpdateCustomerLoading: false,
        addUpdateCustomerError: null,
      };

    case types.APPLY_CUSTOMER_FILTER:
      return { ...state, customerFilters: action.payload };

    /**
     * Bulk Delete customer
     */
    case types.DELETE_BULK_CUSTOMER_IN_PROGRESS:
      return { ...state, bulkDeleteLoading: true, bulkDeleteError: null };
    case types.DELETE_BULK_CUSTOMER_SUCCESS:
      const deletedCustIds = action.payload;
      const _list1 = state.list.filter((c) => deletedCustIds.indexOf(c.id) < 0);
      return {
        ...state,
        list: [..._list1],
        bulkDeleteLoading: false,
        bulkDeleteError: null,
      };
    case types.DELETE_BULK_CUSTOMER_FAILURE:
      return {
        ...state,
        bulkDeleteError: action.error,
        bulkDeleteLoading: null,
      };

    /**
     * Bulk Settle Transactions
     */
    case types.BULK_SETTLE_TXN_IN_PROGRESS:
      return { ...state, bulkSettleLoading: true, bulkSettleError: null };
    case types.BULK_SETTLE_TXN_SUCCESS:
      return { ...state, bulkSettleLoading: false, bulkSettleError: null };
    case types.BULK_SETTLE_TXN_FAILURE:
      return {
        ...state,
        bulkSettleLoading: false,
        bulkSettleError: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
